import './bootstrap';
import Headroom from "headroom.js";
import AOS from "aos";
import Swiper from "swiper/bundle";
import "swiper/css/bundle";
import SmoothScroll from "smooth-scroll";
import fslightbox from "fslightbox";
import vhCheck from "vh-check";

import "./components/toastify";
import "./components/form";

const header = document.getElementById("header-wrapper");
const headroom = new Headroom(header, {
    offset: 20,
});

headroom.init();

const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 500,
    speedAsDuration: true,
    updateURL: false,
    popstate: false,
    topOnEmptyHash: true,
});

const viewportHeight = vhCheck({
    cssVarName: "vh-offset",
    bind: false,
});

// console.log(document.querySelector(".gallery-top"));
if (document.querySelector(".gallery-top")) {
    var galleryTop = new Swiper(".gallery-top", {
        loop: false,
        centeredSlides:false,
        adaptiveHeight:true,
        spaceBetween: 24,
        navigation: {
            nextEl: ".swiper-next",
            prevEl: ".swiper-prev",
        },
        breakpoints: {
            768: {
                slidesPerView: 1,
            },
            992: {
                slidesPerView: 2,
            },
        },
        pagination: {
            el: '.swiper-pagination',
            type:'progressbar'
          },
    });

    // console.log(galleryTop);
}

if (document.querySelector(".rooms")) {
    var rooms = new Swiper(".rooms", {
        loop: true,
        spaceBetween: 24,
        centeredSlides: true,
        slidesPerView: 1,
        breakpoints: {
            768: {
                slidesPerView: 1.5,
            },
        },
        navigation: {
            nextEl: ".room-next",
            prevEl: ".room-prev",
        },
        simulateTouch: false,
    });
}

if (document.querySelector(".similar-flats")) {
    var similarFlats = new Swiper(".similar-flats", {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 10,
        breakpoints: {
            768: {
                slidesPerView: 2,
            },
            1200: {
                slidesPerView: 3,
            },
        },
    });
}

//BURGER MENU
const burgerMenu = document.getElementById("burger-menu");
const burgerMenuOpen = document.getElementById("burger-menu-toggle");
const burgerMenuClose = document.getElementById("burger-menu-close");
const burgerMenuOverlay = document.getElementById("burger-overlay");

burgerMenuOpen.addEventListener("click", () => {
    burgerMenu.classList.add("open");
    burgerMenuOverlay.classList.add("open");
    headroom.freeze();
});

burgerMenuClose.addEventListener("click", () => {
    burgerMenu.classList.remove("open");
    burgerMenuOverlay.classList.remove("open");
    headroom.unfreeze();
});

burgerMenuOverlay.addEventListener("click", () => {
    burgerMenu.classList.remove("open");
    burgerMenuOverlay.classList.remove("open");
    headroom.unfreeze();
});

var burgerMenuHide = function () {
    burgerMenu.classList.remove("open");
    burgerMenuOverlay.classList.remove("open");
    headroom.unfreeze();
};

document.addEventListener("scrollStart", burgerMenuHide, false);

window.onload = function () {
    AOS.init({
        offset: 0,
        duration: 1000,
        delay: 100,
        once: true,
        anchorPlacement: "top-center",
    });

    //Prefooter change contact form on click
    var prefooterInner = document.getElementById("prefooter-inner");
    var formContainer = document.getElementById("form-container");
    var openForm = document.getElementById("show-form-button");
    var closeForm = document.getElementById("close-form-button");
    var openFormButtons = document.getElementsByClassName("show-contact-form-button");

    for (var iOpenFormButtons = 0; iOpenFormButtons < openFormButtons.length; iOpenFormButtons++) {
        openFormButtons.item(iOpenFormButtons).addEventListener("click", (event) => {
            event.preventDefault();
            prefooterInner.classList.remove("show");
            formContainer.classList.add("show");

            formContainer.scrollIntoView();
        });
    }

    closeForm.addEventListener("click", (event) => {
        event.preventDefault();
        prefooterInner.classList.add("show");
        formContainer.classList.remove("show");
    });
};

// Init popover
   $('[data-toggle="popover"]').popover();

   const tooltipButtons = document.querySelectorAll('.tooltip');
   tooltipButtons.forEach(function (button) {
       $(button).on('shown.bs.popover', function () {
           button.classList.add('opened');
       });

       $(button).on('hidden.bs.popover', function () {
           button.classList.remove('opened');
       });
   });
